import { Component } from 'preact';
import IMask from 'imask';
import PropTypes from 'prop-types';
import getUTM from '%/utils/utm';
import { getCookie } from '%/utils/cookies';
import { loadScriptReCAPTCHA } from '%/utils/reCAPTCHA';
import SimpleMessageItem from './SimpleMessageItem';
import SelectableMessageItem from './SelectableMessageItem';

class ChatConversionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      name: '',
      phone: '',
      cpf: '',
      unit: '',
      area: '',
      areaWhatsappLink: '',
      currentMessage: '',
      messageInputMask: '',
      messages: [],
      steps: [
        {
          text: 'Por favor, qual seu nome?',
          time: '',
          sender: 'bot',
          state: 'name',
          mask: /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/,
          minMessageLength: 4,
          selectable: false,
          options: [],
          finalStep: false,
        },
        {
          text: `Prazer em te conhecer! Qual seu telefone?`,
          time: '',
          sender: 'bot',
          state: 'phone',
          mask: '(00) 00000-0000',
          minMessageLength: 15,
          selectable: false,
          options: [],
          finalStep: false,
        },
        {
          text: 'Anotado! Me diz seu CPF, por favor.',
          time: '',
          sender: 'bot',
          state: 'cpf',
          mask: '000.000.000-00',
          minMessageLength: 14,
          selectable: false,
          options: [],
          finalStep: false,
        },
        {
          text: 'Agora, selecione um canal de atendimento e um de nossos especialistas irá tirar todas as suas dúvidas:',
          time: '',
          sender: 'bot',
          state: 'unit',
          mask: '',
          minMessageLength: 0,
          selectable: true,
          options: [],
          finalStep: false,
        },
        {
          text: 'Para finalizar, selecione com qual setor você deseja falar:',
          time: '',
          sender: 'bot',
          state: 'area',
          mask: '',
          minMessageLength: 0,
          selectable: true,
          options: [],
          finalStep: false,
        },
        {
          text: 'Pronto! Agora iremos te redirecionar para o aplicativo do Whatsapp. Basta aguardar! Se necessário, clique no botão abaixo.',
          time: '',
          sender: 'bot',
          state: '',
          mask: '',
          minMessageLength: 0,
          selectable: false,
          options: [],
          finalStep: true,
        },
      ],
      currentStep: '',
      currentOptionValue: '',
    };

    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleEnterEvent = this.handleEnterEvent.bind(this);
    this.handleMessageSend = this.handleMessageSend.bind(this);
    this.handleAutoReply = this.handleAutoReply.bind(this);
    this.handleCloseChat = this.handleCloseChat.bind(this);
  }

  componentDidMount() {
    loadScriptReCAPTCHA();

    this.handleUpdateUnitStepOptions();
    this.handleHeaderCurrentUnit();
    this.handleAutoReply(true);

    const messageInputMask = IMask(this.messageInput, {
      mask: /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/,
    });

    this.setState({
      messageInputMask,
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps() {
    const { open } = this.props;
    const { isOpen } = this.state;

    if (!open) return;

    this.setState({ isOpen: !isOpen });
  }

  handleMessageChange(e, messageToSent = '') {
    const message = e.target ? e.target.value : messageToSent;

    const sanitezedMessage = this.sanitizeMessage(message);

    this.setState({
      currentMessage: sanitezedMessage,
    });
  }

  handleOptionChange(e) {
    const { optionState, optionValue, optionLabel, optionLink } =
      e.target.dataset;

    const stateKeysToUpdate = {
      currentMessage: optionLabel,
      currentOptionValue: optionValue,
    };

    if (optionState === 'unit') {
      this.handleUpdateAreaStepOptions(optionValue);
    } else if (optionState === 'area') {
      stateKeysToUpdate.areaWhatsappLink = optionLink || '';
    }

    this.setState(stateKeysToUpdate);
    this.handleMessageSend();
  }

  handleMessageSend() {
    const { currentMessage, currentStep, currentOptionValue } = this.state;

    const stateValue = currentStep.selectable
      ? currentOptionValue
      : currentMessage;

    if (currentMessage.length === 0) return;

    this.addNewMessage(
      {
        text: currentMessage,
        time: this.getMessageTime(),
        sender: 'user',
        state: currentStep.state,
      },
      () => {
        this.handleUpdateState(currentStep.state, stateValue);
        this.resetCurrentMessage();
        this.scrollBottom();
      },
    );

    setTimeout(() => {
      this.handleAutoReply(false, currentMessage);
    }, 1000);
  }

  handleAutoReply(isFirstReply = false, message = '') {
    const { name, steps } = this.state;
    const messageIsValid = this.messageIsValid(message);

    if (!isFirstReply && !messageIsValid) {
      this.handleErrorMessage();
      return;
    }

    const newMessage = steps.shift();
    newMessage.time = this.getMessageTime();

    if (newMessage.state === 'phone') {
      newMessage.text = `Prazer em te conhecer ${name}! Qual seu telefone?`;
    }

    if (newMessage.finalStep) {
      this.sendConversion();
    }

    this.addNewMessage(newMessage, () => {
      this.setState({ currentStep: newMessage });
      this.handleUpdateMessageInputMask(newMessage.mask);
      this.scrollBottom();
    });
  }

  handleUpdateState(currentState, currentMessage) {
    if (!currentState || !currentMessage) return;

    this.setState({ [currentState]: currentMessage });
  }

  handleUpdateMessageInputMask(mask) {
    const { messageInputMask } = this.state;

    messageInputMask.updateOptions({
      mask,
    });
  }

  handleUpdateUnitStepOptions() {
    const { steps } = this.state;
    const { units } = this.props;
    const indexUnitStep = steps.findIndex(step => step.state === 'unit');

    steps[indexUnitStep].options = units;

    this.setState({
      steps,
    });
  }

  handleUpdateAreaStepOptions(unit) {
    const { whatsappContacts, units: channelUnits } = this.props;
    const { steps } = this.state;

    const filteredUnit = channelUnits.filter(
      channelUnit => channelUnit.value === unit,
    );

    if (!filteredUnit) return;

    const unitName = filteredUnit[0].label;
    const selectableWhatsappContacts = whatsappContacts[unitName];
    const indexAreaStep = steps.findIndex(step => step.state === 'area');

    if (!selectableWhatsappContacts) return;

    if (selectableWhatsappContacts.length > 1) {
      const whatsappContactsOptions = selectableWhatsappContacts.map(
        ({ name, area, link }) => ({
          label: name,
          value: area,
          link,
        }),
      );

      steps[indexAreaStep].options = whatsappContactsOptions;
    } else {
      steps.splice(indexAreaStep, 1);

      this.setState({
        area: selectableWhatsappContacts[0].area,
        areaWhatsappLink: selectableWhatsappContacts[0].link,
      });
    }

    this.setState({
      steps,
    });
  }

  handleHeaderCurrentUnit() {
    const { steps } = this.state;
    const { units, whatsappContacts } = this.props;

    const currentUnitId = Number(getCookie('current_unit') || 0);
    const filteredUnit = units.filter(unit => unit.id === currentUnitId);
    const indexUnitStep = steps.findIndex(step => step.state === 'unit');

    if (filteredUnit.length === 0) return;

    let unit = filteredUnit[0].value;
    const unitLabel = filteredUnit[0].label;
    const unitWhatsappContacts = whatsappContacts[unitLabel];

    if (!unitWhatsappContacts) {
      const whatsappContactsUnits = Object.keys(whatsappContacts);
      const filteredChannelUnits = units.filter(channelUnit =>
        whatsappContactsUnits.includes(channelUnit.label),
      );

      if (filteredChannelUnits.length > 1) {
        steps[indexUnitStep].options = filteredChannelUnits;

        this.setState({
          steps,
        });

        return;
      }

      unit = filteredChannelUnits[0].value;
    }

    steps.splice(indexUnitStep, 1);

    this.setState({
      unit,
      steps,
    });

    this.handleUpdateAreaStepOptions(unit);
  }

  handleErrorMessage() {
    const errorMessages = {
      name: 'Por favor, informe seu nome completo.',
      phone: 'Por favor, informe seu telefone corretamente.',
      cpf: 'Por favor, informe seu cpf corretamente.',
    };

    const { currentStep } = this.state;

    this.addNewMessage(
      {
        text: errorMessages[currentStep.state],
        time: this.getMessageTime(),
        sender: 'bot',
      },
      () => {
        this.scrollBottom();
      },
    );
  }

  handleCloseChat() {
    this.setState({ isOpen: false });
  }

  handleEnterEvent(event) {
    if (event.key !== 'Enter' || !this.messageInput) return;

    event.preventDefault();

    this.handleMessageChange(event, this.messageInput.value);
    this.handleMessageSend();
  }

  getMessageTime() {
    const today = new Date();

    return `${this.addZero(today.getHours())}:${this.addZero(
      today.getMinutes(),
    )}`;
  }

  getDealerIcon() {
    const { dealerFavicon, brandFavicon } = this.props;

    if (dealerFavicon.length > 0) {
      return dealerFavicon;
    }

    return brandFavicon;
  }

  getConversionParams() {
    const { dealerBrand, dealerName } = this.props;
    const { name, phone, cpf, unit, area } = this.state;

    return {
      bait: '-6',
      bait_type: 'whatsapp',
      category: area,
      utmz: getUTM(),
      brand: dealerBrand,
      channel: dealerName,
      product: 'Whatsapp Formulário Interativo',
      name,
      phone,
      cpf,
      unit,
      whatsapping: true,
      whatsapp_no_responder: true,
    };
  }

  addNewMessage(message, callback = () => {}) {
    const { messages } = this.state;
    const updatedMessages = [...messages];

    updatedMessages.push(message);

    this.setState(
      {
        messages: updatedMessages,
      },
      () => {
        callback();
      },
    );
  }

  addZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  sendConversion() {
    const { areaWhatsappLink } = this.state;
    const params = this.getConversionParams();

    window.serviceConversion
      .convert(params)
      .then(() => {})
      .catch(() => {});

    return window.open(areaWhatsappLink);
  }

  resetCurrentMessage() {
    const { messageInputMask } = this.state;

    this.setState({ currentMessage: '' });
    this.messageInput.value = '';
    messageInputMask.updateValue();
  }

  sanitizeMessage(message) {
    return message.replace(/\n/g, ' ').replace(/\s/g, ' ');
  }

  messageIsValid(message) {
    const { currentStep } = this.state;

    return message.length >= currentStep.minMessageLength;
  }

  scrollBottom() {
    const chatboxMessageWrapper = document.querySelector(
      '.chatbox-message-content',
    );

    chatboxMessageWrapper.scrollTo(0, chatboxMessageWrapper.scrollHeight);
  }

  render() {
    const { dealerName } = this.props;
    const { currentMessage, messages, areaWhatsappLink, isOpen } = this.state;

    return (
      <div className={`chatbox-message-wrapper ${isOpen && 'show'}`}>
        <div className="chatbox-message-header">
          <div className="chatbox-message-profile">
            <img
              src={this.getDealerIcon()}
              alt={dealerName}
              className="chatbox-message-image"
            />
            <div>
              <h4 className="chatbox-message-name">{dealerName}</h4>
              <p className="chatbox-message-status">Conta comercial</p>
            </div>
          </div>

          <div className="chatbox-message-close-wrapper">
            <button
              type="button"
              className="chatbox-message-close-wrapper__button"
              onClick={this.handleCloseChat}
            >
              <i className="icon icon-close-b" />
            </button>
          </div>
        </div>

        <div className="chatbox-message-content">
          <div className="chatbox-message-item received">
            <span className="chatbox-message-item-text">
              Seja bem vindo a {dealerName}!
            </span>
            <span className="chatbox-message-item-time">
              {this.getMessageTime()}
            </span>
          </div>

          {messages &&
            messages.map(
              ({ text, time, state, sender, selectable, options, finalStep }) =>
                selectable ? (
                  <SelectableMessageItem
                    text={text}
                    time={time}
                    state={state}
                    options={options}
                    handleClick={this.handleOptionChange}
                  />
                ) : (
                  <SimpleMessageItem
                    text={text}
                    time={time}
                    sender={sender}
                    finalStep={finalStep}
                    areaWhatsappLink={areaWhatsappLink}
                  />
                ),
            )}
        </div>

        <div className="chatbox-message-bottom">
          <div className="chatbox-message-form">
            <textarea
              rows="1"
              value={currentMessage}
              placeholder="Digite uma mensagem"
              className="chatbox-message-input"
              onChange={this.handleMessageChange}
              onKeyDown={this.handleEnterEvent}
              // eslint-disable-next-line no-return-assign
              ref={messageInput => (this.messageInput = messageInput)}
            />
            <button
              type="button"
              className="chatbox-message-submit"
              onClick={this.handleMessageSend}
            >
              <i className="icon icon-chat-send" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ChatConversionForm.defaultProps = {
  open: false,
  dealerFavicon: '',
  dealerName: '',
  dealerBrand: '',
  brandFavicon: '',
  units: [],
  whatsappContacts: '',
};

ChatConversionForm.propTypes = {
  open: PropTypes.bool,
  dealerFavicon: PropTypes.string,
  dealerName: PropTypes.string,
  dealerBrand: PropTypes.string,
  brandFavicon: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  whatsappContacts: PropTypes.arrayOf(PropTypes.string),
};

export default ChatConversionForm;
