export const renderInteractiveWhatsappForm = event => {
  if (!event) return;

  event.preventDefault();

  const chatModal = document.querySelector(
    '.interactive-whatsapp-form__wrapper-content',
  );
  if (!chatModal) return;

  const data = chatModal.dataset;

  window.render(
    window.h(window.WhatsappChatConversionForm, {
      open: true,
      dealerFavicon: data.dealerFavicon,
      dealerName: data.dealerName,
      dealerBrand: data.dealerBrand,
      brandFavicon: data.brandFavicon,
      units: JSON.parse(data.units),
      whatsappContacts: JSON.parse(data.whatsappContacts),
    }),
    chatModal,
  );
};
