import { Component } from 'preact';
import PropTypes from 'prop-types';

class SelectableMessageItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsDisabled: false,
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick(e) {
    const { handleClick } = this.props;

    this.setState({
      optionsDisabled: true,
    });

    handleClick(e);
  }

  render() {
    const { optionsDisabled } = this.state;
    const { text, time, options, state } = this.props;

    return (
      <div className="chatbox-message-selectable">
        <div className="chatbox-message-item received">
          <span className="chatbox-message-item-text">{text}</span>
          <span className="chatbox-message-item-time">{time}</span>
        </div>

        {options &&
          options.map(({ value, label, link }) => (
            <button
              type="button"
              className="chatbox-message-selectable-item"
              data-option-state={state}
              data-option-value={value}
              data-option-label={label}
              data-option-link={link}
              onClick={this.handleButtonClick}
              disabled={optionsDisabled}
            >
              {state === 'unit' && <i className="icon icon-map" />}
              {label}
            </button>
          ))}
      </div>
    );
  }
}

SelectableMessageItem.defaultProps = {
  text: '',
  time: '',
  state: '',
  options: [],
  handleClick: () => {},
};

SelectableMessageItem.propTypes = {
  text: PropTypes.string,
  time: PropTypes.string,
  state: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  handleClick: PropTypes.func,
};

export default SelectableMessageItem;
