import { Component } from 'preact';
import PropTypes from 'prop-types';

class SimpleMessageItem extends Component {
  render() {
    const { sender, text, finalStep, areaWhatsappLink, time } = this.props;

    return (
      <div
        className={`chatbox-message-item ${
          sender === 'user' ? 'sent' : 'received'
        }`}
      >
        <span className="chatbox-message-item-text">
          {text}
          {finalStep && (
            <a
              className="chatbox-message-item-text__whatsapp-btn"
              href={areaWhatsappLink}
              target="_blank"
              rel="noreferrer"
            >
              <i className="icon icon-whatsapp" />
              Abrir Whatsapp
            </a>
          )}
        </span>
        <span className="chatbox-message-item-time">
          {time}
          {sender === 'user' && <i className="icon icon-read-confirmation" />}
        </span>
      </div>
    );
  }
}

SimpleMessageItem.defaultProps = {
  text: '',
  time: '',
  sender: '',
  finalStep: false,
  areaWhatsappLink: '',
};

SimpleMessageItem.propTypes = {
  text: PropTypes.string,
  time: PropTypes.string,
  sender: PropTypes.string,
  finalStep: PropTypes.bool,
  areaWhatsappLink: PropTypes.string,
};

export default SimpleMessageItem;
