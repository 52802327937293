/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import '../auto/global';
import '../auto/stores';
import './components';
import './stores';
// Importando o alias para a pasta de componentes.
import '#';
import Carousel from '../auto/carousel';
import Choices from '../auto/choices';
import MicroModal from '../auto/micromodal';
import serviceFactory from '../auto/autoForceServiceFactory';
import serviceConversionFactory from '../auto/autoBoxOfficeServiceFactory';
import railsDateToChronometer from '../auto/utils/railsDateToChronometer';
import fcaServiceApi from '../auto/utils/fcaServiceApi';
import ShowcaseChoiceVersion from '../auto/components/ShowcaseChoiceVersion/ShowcaseChoiceVersion';
import ConversionFormNoResultApp from '../auto/components/ConversionFormNoResult/ConversionFormApp';
import { makeAllDdmOffersListSyncService } from '../auto/services/DdmServiceV2/makeAllDdmOffersListSyncService';
import { DDMAdapter } from '../auto/services/ddm_service/adapters/ddmAdapter';
import { addExceptionModelsToChannelData } from '../auto/services/ddm_service/utils';
import { renderInteractiveWhatsappForm } from '../auto/utils/interactiveWhatsappForm';
import { renderFeaturesDisplay } from '../auto/utils/featuresDisplay';

// Criando um service que será armazenado como Singleton.
window.service = serviceFactory();
window.serviceConversion = serviceConversionFactory();
window.serviceFca = fcaServiceApi;

window.Carousel = Carousel;
window.Choices = Choices;
window.MicroModal = MicroModal;
window.ShowcaseChoiceVersion = ShowcaseChoiceVersion;
window.ConversionFormNoResultApp = ConversionFormNoResultApp;

window.railsDateToChronometer = railsDateToChronometer;
window.DDMAdapter = DDMAdapter;
window.ddmOffersListSyncService = makeAllDdmOffersListSyncService();
window.helpers = {
  ...window.helpers,
  addExceptionModelsToChannelData,
};
window.renderInteractiveWhatsappForm = renderInteractiveWhatsappForm;
window.renderFeaturesDisplay = renderFeaturesDisplay;

if (process.env.NODE_ENV === 'development') {
  // Necessário para fazer o Preact ficar disponível no React Dev Tools
  require('preact/debug');

  // Adicionando o app do guide apenas em desenvolvimento
  $(document).ready(() => {
    // require('./guide-app.js');
  });
}

$(document).ready(() => {
  // create Swiper object
  const slide = new Carousel('.slide', {
    autoplay: {
      delay: 5000,
    },
    speed: 500,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    on: {
      init() {
        window.videoBG.adjustsHeight('.slide');
      },
    },
  });

  const slideThumbs = new Carousel('.swiper-thumbs-container', {
    direction: 'vertical',
    slidesPerView: 5,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  });

  const slideImages = new Carousel('.swiper-images-container', {
    direction: 'horizontal',
    navigation: {
      nextEl: '.slide-images__control--next',
      prevEl: '.slide-images__control--prev',
    },
    thumbs: {
      swiper: slideThumbs,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      767: {
        direction: 'vertical',
      },
    },
  });
});
